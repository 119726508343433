import * as React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import { Grid } from "@mui/system";
import { EuroSymbol } from "@mui/icons-material";
import { calculateSalary, loadData, periodAmount } from "./Calculator";
import { toMonetary } from "./toMonetary";

export default function App() {
  const [selectedTab, setSelectedTab] = React.useState("yearly");
  const [loading, setLoading] = React.useState(false);

  const [yearVars, setYearVars] = React.useState([]);
  const years = Object.keys(yearVars)
    .map((y) => yearVars[y].SalaryYearVar.id)
    .sort((a, b) => b - a);

  const domain = window.location.hostname.replace(".", "-");

  const isMaltatoday = domain.includes("maltatoday");
  const [year, setYear] = React.useState(isMaltatoday ? 2025 : new Date().getFullYear());

  const fullWidth = isMaltatoday;
  const redirectLink = `https://talexio.com/?utm_source=${domain}&utm_medium=referral&utm_campaign=salary-calculator`;

  const taxStatuses = [
    { label: "Single", value: "single", tooltip: "" },
    { label: "Married", value: "married", tooltip: "" },
    { label: "Parent", value: "parent", tooltip: "" },
    {
      label: "Non-Resident",
      value: "nonResident",
      tooltip:
        "Employees (whether EU or Third Country National) who have been living in Malta for less than 183 consecutive days, fall under the Non Resident Tax bands until the 183 days have elapse.",
    },
  ];
  const [results, setResults] = React.useState({
    net: 0,
    tax: 0,
    ssc: 0,
    totalGross: 0,
    taxableGross: 0,
  });
  const [form, setForm] = React.useState({
    gross: undefined,
    allowance: undefined,
    bonus: undefined,
    taxStatus: "single",
    before1962: false,
    over18: true,
    studentApprentice: false,
  });

  const loadRates = React.useCallback(async () => {
    setLoading(true);
    const data = await loadData();
    const response = await data.json();
    setYearVars(response.result);
    setLoading(false);
  }, [setLoading, setYearVars]);

  React.useEffect(() => {
    loadRates();
  }, []);

  React.useEffect(() => {
    setResults(calculateSalary(year, form, yearVars));
  }, [form, year, setResults, yearVars]);
  return (
    <Container sx={{ backgroundColor: "#eee", padding: "16px 0px" }}>
      <Container>
        <Box
          sx={{
            marginTop: 2,
            marginBottom: 1,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">{isMaltatoday ? "MaltaToday " : ""}Salary Calculator</Typography>
          <Box
            style={{
              fontSize: 12,
              color: "#999",
              display: "flex",
              alignItems: "center",
              gap: 8,
            }}
          >
            Powered by{" "}
            <a href={redirectLink} target="_blank">
              <img
                src="https://salary-calculator.talexio.com/img/talexio_grey.png"
                alt="Talexio"
                style={{ height: 18 }}
              />
            </a>
          </Box>
        </Box>
        <Grid container spacing={1}>
          <Grid
            item
            size={{
              xs: 12,
              sm: 12,
              md: fullWidth ? 12 : 6,
              lg: fullWidth ? 12 : 6,
            }}
          >
            <Card sx={{ marginBottom: 2 }} variant="outlined">
              <CardHeader
                title="Your Gross Salary Details"
                titleTypographyProps={{
                  fontSize: "100%",
                  fontWeight: 700,
                }}
              />
              <Divider />
              <CardContent style={{ paddingBottom: 8 }}>
                <TextField
                  label="Yearly Gross Salary"
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                    input: {
                      startAdornment: <EuroSymbol style={{ marginRight: 8 }} />,
                      inputProps: {
                        min: 0,
                      },
                    },
                  }}
                  fullWidth
                  placeholder="00,000.00"
                  type="number"
                  variant="outlined"
                  margin="normal"
                  value={form.gross}
                  onChange={(e) => {
                    const newValue = e.target.value
                      ? parseFloat(e.target.value)
                      : undefined;
                    setForm((f) => ({
                      ...f,
                      gross: !newValue
                        ? undefined
                        : newValue < 0
                        ? 0
                        : newValue,
                    }));
                  }}
                />
                <Grid container spacing={1}>
                  <Grid size={{ xs: 12, sm: 6 }}>
                    <TextField
                      label="Allowance"
                      slotProps={{
                        inputLabel: {
                          shrink: true,
                        },
                        input: {
                          startAdornment: (
                            <EuroSymbol style={{ marginRight: 8 }} />
                          ),
                          inputProps: {
                            min: 0,
                          },
                        },
                      }}
                      fullWidth
                      placeholder="0.00"
                      type="number"
                      variant="outlined"
                      margin="normal"
                      helperText="Any car cash allowance"
                      value={form.allowance}
                      onChange={(e) => {
                        const newValue = e.target.value
                          ? parseFloat(e.target.value)
                          : undefined;
                        setForm((f) => ({
                          ...f,
                          allowance: !newValue
                            ? undefined
                            : newValue < 0
                            ? 0
                            : newValue,
                        }));
                      }}
                    />
                  </Grid>
                  <Grid size={{ xs: 12, sm: 6 }}>
                    <TextField
                      label="Bonus"
                      slotProps={{
                        inputLabel: {
                          shrink: true,
                        },
                        input: {
                          startAdornment: (
                            <EuroSymbol style={{ marginRight: 8 }} />
                          ),
                          inputProps: {
                            min: 0,
                          },
                        },
                      }}
                      fullWidth
                      placeholder="0.00"
                      type="number"
                      variant="outlined"
                      margin="normal"
                      helperText="Bonus income over the gross salary"
                      value={form.bonus}
                      onChange={(e) => {
                        const newValue = e.target.value
                          ? parseFloat(e.target.value)
                          : undefined;
                        setForm((f) => ({
                          ...f,
                          bonus: !newValue
                            ? undefined
                            : newValue < 0
                            ? 0
                            : newValue,
                        }));
                      }}
                    />
                  </Grid>
                </Grid>
                <FormControl fullWidth sx={{ my: 2 }}>
                  <InputLabel>Tax Status</InputLabel>
                  <Select
                    value={form.taxStatus}
                    label="Tax Status"
                    fullWidth
                    onChange={(e) => {
                      const newTaxStatus = e.target.value ?? "single";
                      setForm((f) => ({
                        ...f,
                        taxStatus: newTaxStatus,
                      }));
                    }}
                  >
                    {taxStatuses.map((t) => (
                      <MenuItem value={t.value} key={`taxstatus_${t.value}`}>
                        {t.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Box sx={{ my: 1 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={form.before1962}
                        disabled={!form.over18}
                        onChange={(e, checked) => {
                          setForm((f) => ({
                            ...f,
                            before1962: checked,
                            over18: checked ? true : f.over18,
                          }));
                        }}
                      />
                    }
                    label={
                      <React.Fragment>
                        <Typography style={{ position: "relative", top: 4 }}>
                          I was born before 1st Jan, 1962
                        </Typography>
                        <Typography
                          variant="caption"
                          style={{ position: "relative", top: -4 }}
                        >
                          Those born before this date pay less SSC
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </Box>
                <Box sx={{ my: 1 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={!form.over18}
                        disabled={form.before1962}
                        onChange={(e, checked) => {
                          setForm((f) => ({
                            ...f,
                            over18: !checked,
                            before1962: checked ? false : f.before1962,
                          }));
                        }}
                      />
                    }
                    label={
                      <React.Fragment>
                        <Typography style={{ position: "relative", top: 4 }}>
                          I am 17 years old or younger
                        </Typography>
                        <Typography
                          variant="caption"
                          style={{ position: "relative", top: -4 }}
                        >
                          Minors pay less SSC
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </Box>
                <Box sx={{ my: 1 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={form.studentApprentice}
                        onChange={(e, checked) => {
                          setForm((f) => ({
                            ...f,
                            studentApprentice: checked,
                          }));
                        }}
                      />
                    }
                    label={
                      <React.Fragment>
                        <Typography style={{ position: "relative", top: 4 }}>
                          I am a student apprentice
                        </Typography>
                        <Typography
                          variant="caption"
                          style={{ position: "relative", top: -4 }}
                        >
                          Apprentices pay less SSC
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid
            item
            size={{
              xs: 12,
              sm: 12,
              md: fullWidth ? 12 : 6,
              lg: fullWidth ? 12 : 6,
            }}
          >
            <Card variant="outlined">
              <CardHeader
                title="Your Net Salary Results"
                style={{
                  paddingBottom: 2,
                }}
                titleTypographyProps={{
                  fontSize: "100%",
                  fontWeight: 700,
                }}
                action={
                  loading ? (
                    <CircularProgress size={24} style={{ marginRight: 8 }} />
                  ) : (
                    <Select
                      value={year}
                      label=""
                      onChange={(e) => {
                        const newYear = parseInt(e.target.value ?? 2024);
                        setYear(newYear);
                      }}
                      style={{ marginRight: 6 }}
                      SelectDisplayProps={{
                        style: {
                          paddingTop: 2,
                          paddingBottom: 4,
                        },
                      }}
                    >
                      {years.map((y) => (
                        <MenuItem value={y} key={`year_${y}`}>
                          {y}
                        </MenuItem>
                      ))}
                    </Select>
                  )
                }
              />
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={selectedTab}
                  onChange={(e, value) => {
                    setSelectedTab(value);
                  }}
                  variant="scrollable"
                  scrollButtons="auto"
                  allowScrollButtonsMobile
                >
                  <Tab label="Yearly" value="yearly" disabled={loading} />
                  <Tab label="Monthly" value="monthly" disabled={loading} />
                  <Tab label="Weekly" value="weekly" disabled={loading} />
                  <Tab label="Daily" value="daily" disabled={loading} />
                  <Tab label="Hourly" value="hourly" disabled={loading} />
                </Tabs>
              </Box>
              <CardContent style={{ paddingBottom: 8 }}>
                <Box sx={{ py: 3, px: 1 }}>
                  <Typography
                    style={{
                      textTransform: "capitalize",
                    }}
                  >
                    Net {selectedTab} Salary (Take-Home Salary)
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 25,
                      color: "#999",
                    }}
                  >
                    {!isNaN(results.net) && results.net > 0
                      ? toMonetary(periodAmount(results.net, selectedTab))
                      : toMonetary(0)}
                  </Typography>
                  <div
                    style={{
                      marginTop: 8,
                      marginBottom: 24,
                      backgroundColor: "#ddd",
                      height: 12,
                      borderRadius: 4,
                      display: "flex",
                      justifyContent: "left",
                      overflow: "hidden",
                      visibility: results.totalGross > 0 ? "inherit" : "hidden",
                    }}
                  >
                    {results.net > 0 && (
                      <Tooltip
                        placement="top"
                        title={`Net: ${toMonetary(
                          periodAmount(results.net, selectedTab)
                        )}`}
                      >
                        <div
                          style={{
                            width: `${Math.round(
                              (results.net / results.totalGross) * 100
                            )}%`,
                            backgroundColor: "#F1605E",
                            transition: "width 0.3s ease-in-out",
                          }}
                        ></div>
                      </Tooltip>
                    )}
                    {results.tax > 0 && (
                      <Tooltip
                        placement="top"
                        title={`Tax: ${toMonetary(
                          periodAmount(results.tax, selectedTab)
                        )}`}
                      >
                        <div
                          style={{
                            width: `${Math.round(
                              (results.tax / results.totalGross) * 100
                            )}%`,
                            backgroundColor: "#317A80",
                            transition: "width 0.3s ease-in-out",
                          }}
                        ></div>
                      </Tooltip>
                    )}
                    {results.ssc > 0 && (
                      <Tooltip
                        placement="top"
                        title={`SSC: ${toMonetary(
                          periodAmount(results.ssc, selectedTab)
                        )}`}
                      >
                        <div
                          style={{
                            width: `${Math.round(
                              (results.ssc / results.totalGross) * 100
                            )}%`,
                            backgroundColor: "#00BFB2",
                            transition: "width 0.3s ease-in-out",
                          }}
                        ></div>
                      </Tooltip>
                    )}
                  </div>
                  <Grid container>
                    <Grid
                      size={{ xs: 12, sm: 4 }}
                      style={{
                        display: "flex",
                        gap: 8,
                        alignItems: "flex-start",
                      }}
                    >
                      <Box
                        sx={{
                          backgroundColor: "#F1605E",
                          borderRadius: 12,
                          width: 12,
                          height: 12,
                          position: "relative",
                          top: 5,
                        }}
                      />
                      <div>
                        <Typography>Net Salary</Typography>
                        <Typography
                          variant="caption"
                          style={{
                            position: "relative",
                            top: -6,
                            color: "#999",
                          }}
                        >
                          {!isNaN(results.net) && results.net > 0
                            ? toMonetary(periodAmount(results.net, selectedTab))
                            : toMonetary(0)}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid
                      size={{ xs: 12, sm: 4 }}
                      style={{
                        display: "flex",
                        gap: 8,
                        alignItems: "flex-start",
                      }}
                    >
                      <Box
                        sx={{
                          backgroundColor: "#317A80",
                          borderRadius: 12,
                          width: 12,
                          height: 12,
                          position: "relative",
                          top: 5,
                        }}
                      />
                      <div>
                        <Typography>Tax</Typography>
                        <Typography
                          variant="caption"
                          style={{
                            position: "relative",
                            top: -6,
                            color: "#999",
                          }}
                        >
                          {!isNaN(results.tax) && results.tax > 0
                            ? toMonetary(periodAmount(results.tax, selectedTab))
                            : toMonetary(0)}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid
                      size={{ xs: 12, sm: 4 }}
                      style={{
                        display: "flex",
                        gap: 8,
                        alignItems: "flex-start",
                      }}
                    >
                      <Box
                        sx={{
                          backgroundColor: "#00BFB2",
                          borderRadius: 12,
                          width: 12,
                          height: 12,
                          position: "relative",
                          top: 5,
                        }}
                      />
                      <div>
                        <Typography>SSC</Typography>
                        <Typography
                          variant="caption"
                          style={{
                            position: "relative",
                            top: -6,
                            color: "#999",
                          }}
                        >
                          {!isNaN(results.ssc) && results.ssc > 0
                            ? toMonetary(periodAmount(results.ssc, selectedTab))
                            : toMonetary(0)}
                        </Typography>
                      </div>
                    </Grid>
                  </Grid>
                </Box>
              </CardContent>
            </Card>
            <Box sx={{ my: 1, mx: 1 }}>
              <Typography gutterBottom>
                <strong>
                  This salary calculator is powered by{" "}
                  <a
                    href={redirectLink}
                    target="_blank"
                    style={{ color: "#000" }}
                  >
                    Talexio
                  </a>
                  .
                </strong>
              </Typography>
              <Typography gutterBottom>
                Talexio is a complete HR system for human resources, recruitment
                and payroll professionals looking to work more efficiently.
              </Typography>
              <a href={redirectLink} target="_blank">
                <img
                  src="https://salary-calculator.talexio.com/img/talexio.png"
                  alt="Talexio"
                  style={{ height: 20, marginTop: 8, marginBottom: 8 }}
                />
              </a>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Container>
  );
}
