import currency from "currency.js";
/**
 * Formats a value as a monetary value
 */
export function toMonetary(input) {
  // Hide decimal part if .00
  const precision = currency(input).cents() === 0 ? 0 : 2;

  return currency(input, {
    errorOnInvalid: true,
    formatWithSymbol: true,
    symbol: "\u20AC", // EUR
    precision,
  }).format();
}
