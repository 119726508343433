export function loadData() {
  return fetch(`https://www.konnekt.com/salary_calculator/yearvars.json`);
}

export function periodAmount(input, period) {
  if (input && period) {
    var fixed = parseFloat(input);
    switch (period) {
      case "yearly":
        return fixed;
      case "monthly":
        return fixed / 12;
      case "weekly":
        return fixed / 52;
      case "daily":
        return fixed / 52 / 5;
      case "hourly":
        return fixed / 52 / 5 / 8;
    }
  }
  return input;
}

export function calculateSalary(financialYear, input, yearVars) {
  const {
    gross,
    allowance,
    bonus,
    taxStatus,
    studentApprentice,
    over18,
    before1962,
  } = input;
  const result = {
    taxableGross: 0,
    tax: 0,
    net: 0,
    ssc: 0,
    totalGross: 0,
  };

  if (!yearVars) {
    return result;
  }

  var currYearVar = yearVars[financialYear];
  if (!currYearVar) {
    return result;
  }

  //to do compute tax rate
  var taxObj = getTaxRate(taxStatus, parseFloat(gross), currYearVar);
  var taxRate = taxObj.taxRate;
  var taxRateDeduction = taxObj.deduct;

  // This has been removed per request of this jira card
  // https://preeosoftware.atlassian.net/browse/SCW-7
  //var govBonus = 512.46;
  var govBonus = 0;
  var carCashModifier = 0.5;
  var carCashCap = 1165.02;

  var grossWeek = parseFloat(gross) / 52;

  result.taxableGross =
    parseFloat(gross) +
    parseFloat(bonus == null ? 0 : bonus) +
    parseFloat(govBonus);
  result.totalGross =
    result.taxableGross + parseFloat(allowance == null ? 0 : allowance);

  var taxableCarCash =
    parseFloat(allowance == null ? 0 : allowance) * carCashModifier;
  var carCash = 0;
  if (taxableCarCash > carCashCap) {
    result.taxableGross +=
      parseFloat(allowance == null ? 0 : allowance) - carCashCap;
    carCash = carCashCap;
  } else {
    result.taxableGross +=
      parseFloat(allowance == null ? 0 : allowance) - taxableCarCash;
    carCash = taxableCarCash;
  }

  result.tax = parseFloat(
    ((result.taxableGross * taxRate - taxRateDeduction) / 12) * 12
  ); // why /12)*12) ???

  if (!studentApprentice) {
    if (
      !over18 &&
      grossWeek >= currYearVar.SalaryYearVar.A_condition_minGross &&
      grossWeek <= currYearVar.SalaryYearVar.A_condition_maxGross
    ) {
      result.ssc = currYearVar.SalaryYearVar.A_weekly_ssc * 52; // A
    }
    if (
      over18 &&
      grossWeek >= currYearVar.SalaryYearVar.B_condition_minGross &&
      grossWeek <= currYearVar.SalaryYearVar.B_condition_maxGross
    ) {
      result.ssc = currYearVar.SalaryYearVar.B_weekly_ssc * 52; // B
    }
    if (before1962) {
      if (
        grossWeek >= currYearVar.SalaryYearVar.C1_condition_minGross &&
        grossWeek <= currYearVar.SalaryYearVar.C1_condition_maxGross
      ) {
        result.ssc = grossWeek * currYearVar.SalaryYearVar.C1_weekly_ssc * 52; // C
      } else if (grossWeek >= currYearVar.SalaryYearVar.D1_condition_minGross) {
        result.ssc = currYearVar.SalaryYearVar.D1_weekly_ssc * 52; // D
      }
    } else {
      if (
        grossWeek >= currYearVar.SalaryYearVar.C2_condition_minGross &&
        grossWeek <= currYearVar.SalaryYearVar.C2_condition_maxGross
      ) {
        result.ssc = grossWeek * currYearVar.SalaryYearVar.C2_weekly_ssc * 52; // C
      } else if (grossWeek >= currYearVar.SalaryYearVar.D2_condition_minGross) {
        result.ssc = currYearVar.SalaryYearVar.D2_weekly_ssc * 52; // D
      }
    }
  } else {
    var checkNI = grossWeek * 0.1;
    if (!over18) {
      // E
      if (checkNI > currYearVar.SalaryYearVar.E_condition_minGross) {
        result.ssc = currYearVar.SalaryYearVar.E_weekly_ssc * 52;
      } else {
        result.ssc = checkNI * 52;
      }
    } else {
      // F
      if (checkNI > currYearVar.SalaryYearVar.F_condition_minGross) {
        result.ssc = currYearVar.SalaryYearVar.F_weekly_ssc * 52;
      } else {
        result.ssc = checkNI * 52;
      }
    }
  }

  result.net = parseFloat(
    result.taxableGross - result.tax - result.ssc + carCash
  );

  return result;
}

function getTaxRate(status, gross, currYearVar) {
  gross = Math.ceil(gross);
  var returnData = null;

  var rates = currYearVar.SalaryTaxRate.filter((v) => {
    return v.code === status;
  });

  var largestRateBracket = null;
  rates.forEach(function (obj) {
    if (gross >= obj.chargeFrom && gross <= obj.chargeTo) {
      returnData = {
        taxRate: obj.rate,
        deduct: obj.deduct,
      };
      return false;
    }
    if (largestRateBracket == null || largestRateBracket.chargeFrom <= gross) {
      largestRateBracket = obj;
    }
  });

  if (returnData == null && largestRateBracket != null) {
    returnData = {
      taxRate: largestRateBracket.rate,
      deduct: largestRateBracket.deduct,
    };
  }
  return returnData;
}
